import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { HelperService } from 'src/app/helper.service';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ParentsService } from 'src/app/services/parents.service';
import { SessionService } from 'src/app/services/session.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-admin-new-session',
  templateUrl: './admin-new-session.component.html',
  styleUrls: ['./admin-new-session.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdminNewSessionComponent implements OnInit, OnDestroy {
  isMultipleSession = false;
  isInsufficientBalance: boolean;
  showLeaves: boolean = false;
  isExpertOnLeave: boolean = false;
  isExpertSelected = false;
  showBookSession = false;
  ischildSelected = false;
  isSessionTypeSelected = false;
  isSessionDurationtSelected = false;
  getSlotsEnabled = false;
  showSlots = false;
  expertSelected: AngularFirestoreDocument;
  childSelected: AngularFirestoreDocument;
  sessionTypes: any;
  selectedSessionType: string;
  selectedExpertLeaves: any = [];
  selectedParentBalance = {};
  slotDuration: Number;
  sessionValue:number;
  manualTime = '';
  chosenDate: Date;
  experts: AngularFirestoreDocument[] = [];
  children: AngularFirestoreDocument[] = [];
  expertSub: Subscription;
  childSub: Subscription;
  selectedSessionDate: Date;
  selectedDate: Date;
  isLessThanSlotRange: boolean;
  @ViewChild('time', { static: false }) mTime: ElementRef;
  @ViewChild('slotList', { static: false }) slotList: ElementRef;
  selectedSlot: any;
  sessionEndDateTime: Date;
  sessionStartTime: any;
  sessionEndTime: any;
  disableSlots = false;
  disableBookButton = false;
  indianDate: any
  isExpertActive: boolean = true;
  selectedDays: string[] = [];
  noOfWeeks:any;
  scheduledSessionIds: any[];
  scheduledSessionDetails: any[];
  totalScheduledSessions: any;
  slotEndHour:any;
  slotEndMinute:any;
  isLoading: boolean = false;
  noOfWeeksErrorMsg:string="";
  selectedDaysErrorMsg:string="";
  isBookingForFirstTime: boolean = false;

  constructor(
    private notificationService: NotificationService,
    private adminService: AdminService,
    private sessionService: SessionService,
    private firestore: AngularFirestore,
    public helper: HelperService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  async ngOnInit() {
   this.expertSub = this.adminService.ExpertList.subscribe(
      (expertList: AngularFirestoreDocument[]) => {
        this.experts = expertList;
      }
    );

    await this.adminService.isSupport();
  }
  getDays() {
    return this.helper.days;
  }
  onExpertSelected(event: AngularFirestoreDocument) {
    this.isExpertSelected = true;
    this.isExpertOnLeave = false;
    this.ischildSelected = false;
    this.isSessionDurationtSelected = false;
    this.isSessionTypeSelected = false;
    this.sessionService.openSlots = [];
    this.showSlots = false;
    this.showBookSession = false;
    this.chosenDate = undefined;
    this.expertSelected = event;

    // checking the activity status of expert
    if(this.expertSelected['activityStatus'] == "Session Disabled" || this.expertSelected['activityStatus'] == "Session & Consultation Disabled"){
      this.isExpertActive = false;
      return;
    }

    this.isExpertActive = true;

    this.childSub = this.adminService
      .getFilteredStudentList(event['id'])
      .subscribe((childList: AngularFirestoreDocument[]) => {
        this.children = childList;
        this.selectedExpertLeaves = [];
        let now = firebase.firestore.Timestamp.now();
        let docRef = this.firestore.collection('leaves', ref => ref.where('leaveBy', '==', this.expertSelected['id']).where('leaveStartDateTime', '>=', now))
        docRef.get().subscribe((results) => {
          // storing the leaves
          if (!results.empty) {
            results.forEach(result => {
              this.selectedExpertLeaves.push(result.data())
            })
            let size = this.selectedExpertLeaves.length;
            this.isExpertOnLeave = true;
            console.log('total upcoming leaves  ', size);
          }
        })
      });
  }

  //changed for allow admin to book session even parent have insufficient balance.
  onchildSelected(event: AngularFirestoreDocument) {
    this.isInsufficientBalance = false;
    this.ischildSelected = true;
    this.childSelected = event;
    let ref = this.firestore.collection('balances').doc(this.childSelected['id']);
    ref.get().subscribe((result) => {
      if (result.exists) {
        this.selectedParentBalance = result.data();
        let balanceDoc: any = result.data();
        if (balanceDoc.currentBalance == 0) {
          this.isInsufficientBalance = true;
        }
      }
    })

  }

  onSessionDurationSelected(event: any) {
    this.isSessionDurationtSelected = true;
    if(event.value === "fullSessionPaid"){
      this.slotDuration = 60;
      this.sessionValue = 1.00;
    }else if(event.value === "halfSessionPaid"){
      this.slotDuration = 30;
      this.sessionValue = 2/3;
    }else if(event.value === "halfSessionUnpaid"){
      this.slotDuration = 30;
      this.sessionValue = 0.50;
    }
    this.manualTime = '';
    //this.mTime.nativeElement.value = null;
    // console.log(this.slotDuration);

    //changed for creating serviceTypes drop-down
    for (let i = 0; i < this.childSelected['myExperts'].length; i++) {
      if (this.expertSelected['id'] == this.childSelected['myExperts'][i].educatorId) {
        this.sessionTypes = this.childSelected['myExperts'][i].service;
        break;
      }
    }

  }

  onSessionTypeSelected(event: any) {
    this.isSessionTypeSelected = true;
    this.selectedSessionType = event;
  }

  onDateSelected(d: Date) {
    this.disableBookButton = true;
    this.showBookSession = false;
    let date = new Date(d);
    this.chosenDate = date;
    this.getSlotsEnabled = true;
    this.showSlots = false;
    let currentTime = new Date();
    let now = currentTime;
    if (date.getDate() >= now.getDate()) {
      this.selectedSessionDate = this.helper.istTime3(date, 0, 0);

      this.selectedDate = new Date(date);
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;

    } else if (date.getMonth() > now.getMonth()) {
      this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
      this.selectedDate = new Date(date);
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;

    } else if (date.getFullYear() > now.getFullYear()) {
      this.selectedSessionDate = this.helper.istTime3(date, 0, 0);
      this.selectedDate = new Date(date);
      this.getSlotsEnabled = true;
      this.isLessThanSlotRange = false;

    } else {
      this.getSlotsEnabled = false;
      this.isLessThanSlotRange = true;
    }

    this.getFreeSlots();
  }



  finalTime(time: string) {
    this.selectedSlot = time;
    this.setSessionTimings(true);
    this.showBookSession = true;
    this.disableSlots = true;
    this.disableBookButton = false;
  }

  async getFreeSlots() {
    this.getSlotsEnabled = false;
    this.showSlots = true;
    let id = this.expertSelected['id'];
    let date = this.chosenDate;
    let slotDuration = this.slotDuration;
    this.sessionService.checkForSlots(id, date, slotDuration);
  }

  onSlotSelected(slotValue) {
    //(<HTMLInputElement> document.getElementById("sessionRequestBtn")).disabled = false;
    this.disableBookButton = false;
    this.showBookSession = true;
    this.selectedSlot = slotValue;
    //this.mTime.nativeElement.disabled = true;
    this.setSessionTimings();
  }

  setSessionTimings(isManual = false) {
    let sessionStartTime = this.selectedSlot.split(':');

    // to get session starting time in minutes
    const startingHour = parseInt(sessionStartTime[0], 10);
    const startingMinute = parseInt(sessionStartTime[1], 10);
    sessionStartTime = startingHour * 60 + startingMinute;
    let slotDuration = this.slotDuration;

    // this is session end time in minutes
    let sessionEndTime = sessionStartTime + slotDuration;

    let endHour = Math.floor(sessionEndTime / 60);
    let endMinute = sessionEndTime % 60;

    this.slotEndHour=endHour;
    this.slotEndMinute=endMinute;
    // creating a session end timestamp for filter comparison    
    let istSessionEndDateTime = this.helper.istTime3(this.selectedDate, endHour, endMinute);
    this.sessionEndDateTime = istSessionEndDateTime;


    if (endMinute == 0) {
      sessionEndTime = endHour + ':' + endMinute + '0';
    } else if (endMinute > 0 && endMinute < 10) {
      sessionEndTime = endHour + ':' + '0' + endMinute;
    } else {
      sessionEndTime = endHour + ':' + endMinute;
    }

    this.sessionStartTime = this.selectedSlot;
    this.sessionEndTime = sessionEndTime;
    if (isManual) {
      console.log(this.sessionService.isOutOfScheduledHoursForBooking(this.sessionStartTime, this.slotDuration))
      if (this.sessionService.isOutOfScheduledHoursForBooking(this.sessionStartTime, this.slotDuration)) { this.manualTime = "Session is already booked"; this.manualTime = sessionEndTime; }
      else
        this.manualTime = sessionEndTime;
    }
  }

  dateFilter(date: Date) {
    let d = new Date(date);
    let currentTime = new Date();
    let currentOffset = currentTime.getTimezoneOffset();
    let ISTOffset = 330;   // IST offset UTC +5:30 
    let now = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);
    if (
      d.getDate() >= now.getDate() &&
      d.getMonth() == now.getMonth() &&
      d.getFullYear() === now.getFullYear()
    )
      return true;
    else if (
      d.getMonth() > now.getMonth() &&
      d.getFullYear() === now.getFullYear()
    )
      return true;
    else if (d.getFullYear() > now.getFullYear()) return true;
    else if (d.getFullYear() < now.getFullYear()) return false;
  }
  onWeeksInputChange(week: string) {
    this.noOfWeeks = week;
    this.noOfWeeksErrorMsg="";
  }
  expertOnLeave(date){
    let sessionStartTime = this.selectedSlot.split(':');
    // to get session starting time in minutes
    const startingHour = parseInt(sessionStartTime[0], 10);
    const startingMinute = parseInt(sessionStartTime[1], 10);
    sessionStartTime = startingHour * 60 + startingMinute;
  
    let onLeave= this.sessionService.isOutOfLeaveRange(date.toDate(),sessionStartTime,this.slotDuration,this.selectedExpertLeaves);
     
    // for (let k = 0; k < this.selectedExpertLeaves.length; k++) {
    //   console.log(moment(date).isBetween(this.selectedExpertLeaves[k].leaveStartDateTime.toDate(),this.selectedExpertLeaves[k].leaveEndDateTime.toDate(),"day",'[]'),date.toDate());
    //   if(moment(date).isBetween(this.selectedExpertLeaves[k].leaveStartDateTime.toDate(),this.selectedExpertLeaves[k].leaveEndDateTime.toDate(),"day",'[]')){
    //     onLeave=true;
    //     break;
    //   }else{
    //     onLeave=false;
    //   }
    // }
    return onLeave;
  }
  saveSession() {
    this.scheduledSessionIds = [];
    this.scheduledSessionDetails = [];
    this.totalScheduledSessions = 0;

    let session = {};
    const createdOn = firebase.firestore.FieldValue.serverTimestamp();
    let datesArray=[];
    session['sessionValue'] = this.sessionValue;

    session['createdOn'] = createdOn;

    session['startTime'] = this.sessionStartTime;
    session['endTime'] = this.sessionEndTime;
    session['slotDuration'] = this.slotDuration;

    session['createdBy'] = this.adminService.currentUser['id'];
    session['createdByName'] = this.adminService.currentUser['fullName'];
    session['bookedBy'] = firebase.firestore.FieldValue.arrayUnion(this.adminService.currentUser['id']);

    session['attendedBy'] = { parent: false, expert: false, admin: false };

    session['createdWith'] = this.expertSelected['id'];
    session['createdWithName'] = this.expertSelected['fullName'];

    session['sessionExpertId'] = this.expertSelected['id'];
    session['sessionExpertName'] = this.expertSelected['fullName'];

    session['sessionStudentId'] = this.childSelected['id'];
    session['sessionStudentName'] = this.childSelected['childName'];
    session['sessionParentName'] = this.childSelected['fullName'];

    session['parentInteraktId'] = this.childSelected['interaktId'];
    session['expertInteraktId'] = this.expertSelected['interaktId'];


    // let service: any;
    // for (let i = 0; i < this.childSelected['myExperts'].length; i++) {
    //   if (this.expertSelected['id'] == this.childSelected['myExperts'][i].educatorId) {
    //     service = this.childSelected['myExperts'][i].service;
    //     break;
    //   }
    // }

    session['serviceType'] = this.selectedSessionType;
    session['status'] = 'Scheduled';
    session['expertPaid'] = false;

    if(this.isMultipleSession){     
      this.checkFirstSessionBooking();
  
      if( this.noOfWeeks===undefined){
        this.noOfWeeksErrorMsg="Please enter no of weeks";
      }
      if(this.selectedDays.length===0){
        this.selectedDaysErrorMsg="Please choose days";
      }
      if(this.noOfWeeks!=undefined && this.selectedDays.length>0){
        this.isLoading=true;
        let multiSessionBatchId = this.helper.uuidv4();
        let weeks=this.noOfWeeks;
        for (let i = 0; i < weeks; i++) {
          this.selectedDays.map(day=>{
           let date=moment(this.selectedSessionDate).day(day).add(i,"week");
            if(date.isSameOrAfter(moment(this.selectedSessionDate))){
              if(this.expertOnLeave(date)){
                if(datesArray.some( item => item.valueOf()===date.valueOf())){
                  datesArray.push(date.add(1,"week"));
                }else{
                  datesArray.push(date);
                } 
              }
            }else{
              datesArray.push(date.add(1,"week"));
            }
          })
         
        }  
       
        datesArray.sort(function (x, y) { //sorting the final filtered list based on date
          return x- y;
        })
  
        let slotsLeftToBeOperatedOn = datesArray.length;
  
        if(this.selectedParentBalance['currentBalance']<datesArray.length){
          this.toastr.error("Parent Balance is not sufficient to Book sessions more than "+this.selectedParentBalance['currentBalance'],"Insufficient Balance!");
          this.isLoading=false;
          return false;
        }
        datesArray.forEach(async (date) => {
          
          let dateStringForId = date.toDate().getDate() + '-' + (date.toDate().getMonth() + 1) + '-' + date.toDate().getFullYear();
          let slotId=dateStringForId + this.sessionStartTime + '-' + this.sessionEndTime + this.expertSelected['id'];
          let slotIds=[];
          if(this.slotDuration===60){
            slotIds.push(dateStringForId + this.sessionStartTime + '-' + this.sessionEndTime + this.expertSelected['id']);
            slotIds.push(dateStringForId + this.sessionStartTime.slice(0, -2) + '30' + '-' + this.sessionEndTime + this.expertSelected['id']);
            slotIds.push(dateStringForId + this.sessionStartTime + '-' + this.sessionStartTime.slice(0, -2) + '30' + this.expertSelected['id']);
          }else{
            slotIds.push(dateStringForId + this.sessionStartTime + '-' + this.sessionEndTime + this.expertSelected['id']);
            let startTime=this.sessionStartTime.split(":");
            if(this.sessionStartTime.slice(-2)==="00"){
              slotIds.push(dateStringForId + this.sessionStartTime + '-' +(+startTime[0]+1)+":00"+ this.expertSelected['id']);
            }else if(this.sessionStartTime.slice(-2)==="30"){
              slotIds.push(dateStringForId + this.sessionStartTime.slice(0,-2)+"00" + '-' + this.sessionEndTime+ this.expertSelected['id']);
            } 
          }
        
          let id = this.helper.uuidv4();
          let topicID = this.helper.uuidv4();
          // creating a session end timestamp for filter comparison    
          let istSessionEndDateTime = this.helper.istTime3(date.toDate(), this.slotEndHour, this.slotEndMinute);
          let chosenDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date(date));
         
          // running scheduler function
          await firebase.firestore().runTransaction(async (transaction) => {
            // reference to the scheduled session for this slot.
            let sessionRef = firebase.firestore().collectionGroup('sessions');
            const queryRef = sessionRef.where('sessionExpertId', '==', this.expertSelected['id']).where('sessionDate', '==', chosenDateTimestamp).where('status', 'in', ['Scheduled', 'Session Request']).orderBy('sessionEndDateTime', 'asc')
  
            await queryRef.get().then((sessionDoc) => {
              // if the session for same slot is not booked already.
              if (sessionDoc.empty) {
                session['id'] = id;
                session['slotId'] = slotId;
                session['topicID'] = topicID;
    
                session['sessionEndDateTime'] = istSessionEndDateTime;
                session['sessionDate'] = date.toDate();
            
                session['bookedWithMultipleSessions'] = true;
                session['multiSessionBatchId'] = multiSessionBatchId;

                const newDate=date.toDate();
                const timeinMiliSec = this.helper.getMondayInMiliSeconds(newDate);
               
                let scheduledRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(id);
                // let scheduledRef = firebase.firestore().collection('sessions').doc(id);
                transaction.set(scheduledRef.ref, session);
  
                this.totalScheduledSessions += 1;
                this.scheduledSessionIds.push(id);
                this.scheduledSessionDetails.push({ date: date.toDate(), startTime: this.sessionStartTime, endTime: this.sessionEndTime})
              
              }else {
                let sessionExist=false;
                sessionDoc.forEach(async (doc) => {
                  let sessionData: any;
                  sessionData = doc.data();
                 
                  if(slotIds.includes(sessionData.slotId)){
                     
                      sessionExist=true;
                      return;
                  }   
                }) 
               
                if(!sessionExist){
                  session['id'] = id;
                  session['slotId'] = slotId;
                  session['topicID'] = topicID;
      
                  session['sessionEndDateTime'] = istSessionEndDateTime;
                  session['sessionDate'] = date.toDate();
              
                  session['bookedWithMultipleSessions'] = true;
                  session['multiSessionBatchId'] = multiSessionBatchId;
      
                  const timeinMiliSec = this.helper.getMondayInMiliSeconds(date.toDate());
                  console.log(timeinMiliSec,"timeinMiliSec");
                  let scheduledRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(id);
                  // let scheduledRef = firebase.firestore().collection('sessions').doc(id);
                  transaction.set(scheduledRef.ref, session);
                  this.totalScheduledSessions += 1;
                  this.scheduledSessionIds.push(id);
                  this.scheduledSessionDetails.push({ date: date.toDate(), startTime: this.sessionStartTime, endTime: this.sessionEndTime})
                }    
              }
            })
          }).then(() => {
            console.log('session created successfully');
            slotsLeftToBeOperatedOn -= 1;
            if (slotsLeftToBeOperatedOn == 0) {
              this.onMultiSessionsBooked();
            }
          }).catch((error) => {
            console.error("Error creating session ", error);
            // slotsLeftToBeOperatedOn -= 1;
            // if (slotsLeftToBeOperatedOn == 0) {
            //   this.clearData(availableSlots);
            //   this.onSessionsBooked();
            // }
          });
    
        })
      }
      
    }else{
      let id = this.helper.uuidv4();
      var docRef;
      let topicID = this.helper.uuidv4();
      const timeinMiliSec = this.helper.getMondayInMiliSeconds(this.selectedSessionDate);
      console.log(timeinMiliSec,"timeinMiliSec");
       docRef = this.firestore.collection("new sessions").doc(`${timeinMiliSec}`).collection("sessions").doc(id);
      // docRef = this.firestore.collection('sessions').doc(id);

      // creating the unique id for the session
      let dateStringForId = this.selectedSessionDate.getDate() + '-' + (this.selectedSessionDate.getMonth() + 1) + '-' + this.selectedSessionDate.getFullYear();
      let slotId = dateStringForId + this.sessionStartTime + '-' + this.sessionEndTime + this.expertSelected['id'];

  
      session['id'] = id;
      session['slotId'] = slotId;
      session['topicID'] = topicID;
  
      session['sessionEndDateTime'] = this.sessionEndDateTime;
      session['sessionDate'] = this.selectedSessionDate;
  
      // let service: any;
      // for (let i = 0; i < this.childSelected['myExperts'].length; i++) {
      //   if (this.expertSelected['id'] == this.childSelected['myExperts'][i].educatorId) {
      //     service = this.childSelected['myExperts'][i].service;
      //     break;
      //   }
      // }
  
      let date = new Date(this.selectedDate);
      let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  
      // notification for parent
      let body1 = {
        'userId': this.childSelected['interaktId'],
        'event': 'new session booked by admin - notify parent',
        'traits': {
          'sessionType': this.selectedSessionType,
          'childName': this.childSelected['childName'],
          'expertName': this.expertSelected['fullName'],
          'date': dateString,
          'time': this.sessionStartTime + ' IST'
        }
      }
  
      // notification for expert
      let body2 = {
        'userId': this.expertSelected['interaktId'],
        'event': 'new session booked by admin - notify expert',
        'traits': {
          'sessionType': this.selectedSessionType,
          'childName': this.childSelected['childName'],
          'date': dateString,
          'time': this.sessionStartTime + ' IST'
        }
      }

        let sessionRef = firebase.firestore().collectionGroup('sessions');
        const queryRef =  sessionRef.where('sessionStudentId', '==', this.childSelected["id"]);
        queryRef.get().then(result=>{
            console.log(result.size,"size");
            if(result.size<1){
              this.isBookingForFirstTime= true;
            }
        docRef
          .set(session)
          .then(async () => {
            await this.notificationService.sendWhatsappNotification(body1);
            await this.notificationService.sendWhatsappNotification(body2);
            if(this.isBookingForFirstTime){
              this.addPackValidity();
            }
            this.onSessionUpdated();
          })
          .catch(function (error) {
            console.error('Error creating Question: ', error);
          });
           
        }) 
    }
  }
 async checkFirstSessionBooking(){
    let sessionRef = firebase.firestore().collectionGroup('sessions');
    const queryRef =  sessionRef.where('sessionStudentId', '==', this.childSelected["id"]);
    await queryRef.get().then(result=>{
        console.log(result.size,"size");
        if(result.size<1){
          this.isBookingForFirstTime= true;
        }
    }) 
  }

  onSessionUpdated = () => {
    this.isExpertSelected = false;
    this.showSlots = false;
    this.isMultipleSession = null;
    //(<HTMLInputElement> document.getElementById("sessionRequestBtn")).disabled = true;
    this.showBookSession = false;
    var message = 'Your session has been scheduled successfully.';
    this.toastr.success(message, 'New Session Confirmed!');
    this.router.navigate(['/support/dashboard']);
  };

  async onMultiSessionsBooked() {
    // creating a transaction for total booked sessions
    let data: any = {};
    const currentTimeStamp: any = firebase.firestore.Timestamp.now();
    data.userId =  this.childSelected['id'];
    data.reason = 'Scheduled by Admin';
    data.status = 'Successfull';
    data.sessionId = this.scheduledSessionIds;
    data.scheduledSessionsDetails = this.scheduledSessionDetails;
    data.parentId =  this.childSelected['id'];
    data.returnedSessionValue = 0;
    data.sessionExpertName =this.expertSelected['fullName'];
    data.transactionType = 'session';
    data.transactionValue = -(this.totalScheduledSessions*this.sessionValue);
    data.cretedViaMultipleBookings = true;
    data.createdAt = currentTimeStamp;
    
    if(this.totalScheduledSessions>0){
      await this.firestore.collection('transactions').add(data).then(() => {
        var message = this.totalScheduledSessions+' sessions has been scheduled successfully.';
        this.toastr.success(message, 'New Session Confirmed!');
         // calling the notification function
          this.sendNotifications();
      }).catch(err => {
        console.log('error creating the transaction ', err);
        this.isLoading=false;
      });

      if(this.isBookingForFirstTime){
        this.addPackValidity();
      }
     
    }else{
      this.isLoading=false;
      this.toastr.error("Sessions not created please check availability of the expert on selected dates and slots", 'Slots Not Available');
    }
  
  }

  async sendNotifications() {
    let firstSession: any = this.scheduledSessionDetails[0];
    let date = new Date(firstSession.date);
   
    let dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    let body1: any = {};

     // notification for parents for successfully booked sessions
     body1 = {
      'userId': this.childSelected['interaktId'],
      'event': 'multi sessions booked by admin - notify expert',
      'traits': {
        'service': this.selectedSessionType,
        'childName': this.expertSelected['fullName'],
        'totalSessions': this.totalScheduledSessions,
        'date': dateString,
        'time': firstSession.startTime + ' IST',
        'link': 'https://connect.mykinderpass.com/parent/dashboard',
      }
    }

    // notification for experts for successfully booked sessions
    let body2 = {
      'userId': this.expertSelected['interaktId'],
      'event': 'multi sessions booked by admin - notify expert',
      'traits': {
        'service': this.selectedSessionType,
        'childName': this.childSelected['childName'],
        'totalSessions': this.totalScheduledSessions,
        'date': dateString,
        'time': firstSession.startTime + ' IST',
        'link': 'https://connect.mykinderpass.com/expert/dashboard',
      }
    }

      // sending the notification to parent.
      await this.notificationService.sendWhatsappNotification(body1);

      // sending the notification to expert.
      await this.notificationService.sendWhatsappNotification(body2);


    // taking user back to dashboard
    this.isLoading=false;
    this.isExpertSelected = false;
    this.showSlots = false;
    this.isMultipleSession = null;
    this.showBookSession = false;
    this.router.navigate(['/support/dashboard']);
  }
  addPackValidity(){
    let validity;
    if(this.childSelected['numberOfSessions']===24){ 
      validity=180;
    }else if(this.childSelected['numberOfSessions']===36){
      validity=270;
    }else if(this.childSelected['numberOfSessions']===48){
      validity=360;
    }
    let date=new Date();
    let validTill=moment(date).add(validity, 'days').toDate();
    let gracePeriod=moment(validTill).add(30, 'days').toDate();
    console.log(validTill,"validTill",gracePeriod);
    let userRef=this.firestore.collection("users").doc(this.childSelected['id']).update({"validTill":validTill,"gracePeriod":gracePeriod}).then(result=>{
      console.log('valid till added successfully');
    }).catch(err => {
      console.log("error",err);
    }
  )
}

  setSelectedDays(value: string) {
    var index = this.selectedDays.findIndex(day => day ===value);
    if (index === -1) this.selectedDays.push(value);
    else this.selectedDays.splice(index, 1);
    this.selectedDaysErrorMsg="";
  }

  ngOnDestroy() {
    if (this.expertSub)
      this.expertSub.unsubscribe();
    if (this.childSub)
      this.childSub.unsubscribe();
  }
}
