export const environment = {
	production: true,
	firebase : {
		apiKey: "AIzaSyDJ36UGjKxTMn0y7P0pgyIP7ueyuIXaHaM",
		authDomain: "tactopus-connect.firebaseapp.com",
		projectId: "tactopus-connect",
		storageBucket: "tactopus-connect.appspot.com",
		messagingSenderId: "717427265865",
		appId: "1:717427265865:web:8a29d1160c72df46538a98",
		measurementId: "G-1PL8W9JZ20"
	  },
	cloudFunctions : {
		createOrder: 'https://us-central1-tactopus-connect.cloudfunctions.net/createOrder',
		capturePayment: 'https://us-central1-tactopus-connect.cloudfunctions.net/capturePayments'
	},

	RAZORPAY_KEY_ID:'rzp_live_vuZzjLqVZeUacH'
  };