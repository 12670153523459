import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { SigninComponent } from './signin/signin.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { ParentRegisterComponent } from './parent-register/parent-register.component';
import { ExpertRegisterComponent } from './expert-register/expert-register.component';
import { ParentDashboardComponent } from './parent-dashboard/parent-dashboard.component';
import { ExpertDashboardComponent } from './expert-dashboard/expert-dashboard.component';
import { SupportDashboardComponent } from './support-dashboard/support-dashboard.component';
import { NewSessionRequestComponent } from './new-session-request/new-session-request.component';
import { ExpertNewSessionComponent } from './expert-new-session/expert-new-session.component';
import { InvalidLoginComponent } from './invalid-login/invalid-login.component';
import { SalesAdminDashboardComponent } from './sales-admin-dashboard/sales-admin-dashboard.component';
import { SalesAdminNewSessionRequestComponent } from './sales-admin-new-session-request/sales-admin-new-session-request.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminNewSessionComponent } from './admin/admin-new-session/admin-new-session.component';
import { InviteDialog } from './invite/invite.component';
import { AdminSelectedParentComponent } from './admin/admin-parents-tab/admin-selected-parent/admin-selected-parent.component';
import { ParentDataResolver } from './admin/admin-parents-tab/admin-selected-parent/parentDataResolver.service';
import { ParentSigninComponent } from './parent-signin/parent-signin.component';
import { ExpertSigninComponent } from './expert-signin/expert-signin.component';
import { SalesAdminSigninComponent } from './sales-admin-signin/sales-admin-signin.component';
import { SupportAdminSigninComponent } from './support-admin-signin/support-admin-signin.component';
import { AdminExpertSelectedComponent } from './admin/admin-expert-tab/admin-expert-selected/admin-expert-selected.component';
import { SupportAdminRegisterComponent } from './support-admin-register/support-admin-register.component';
import { SalesAdminRegisterComponent } from './sales-admin-register/sales-admin-register.component';
import { ParentSignin2Component } from './parent-signin2/parent-signin2.component';
import { MultiSessionComponent } from './multi-session/multi-session.component';

import { ProgramAdminSigninComponent } from './program-admin-signin/program-admin-signin.component';
import { ProgramAdminRegisterComponent } from './program-admin-register/program-admin-register.component';
import { PaymentComponent } from './payment/payment.component';
import { FindExpertsComponent } from './find-experts/find-experts.component';
import { ParentSsoLoginComponent } from './parent-sso-login/parent-sso-login.component'
import { FeedbackPageComponent } from './feedback-page/feedback-page.component';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';


const routes: Routes = [
	// { path: '', component: ParentSigninComponent },
	{ path: '', component: ParentSsoLoginComponent },
	{ path: ':token', component: ParentSsoLoginComponent },
	{ path: 'program/signin', component: ProgramAdminSigninComponent },
	{ path: 'support/signin', component: SupportAdminSigninComponent },
	{ path: 'sales/signin', component: SalesAdminSigninComponent },
	{ path: 'parent/signin', component: ParentSsoLoginComponent },
	{ path: 'parent/signin2', component: ParentSignin2Component },
	{ path: 'expert/signin', component: ExpertSigninComponent },

	{ path: 'program/register', component: ProgramAdminRegisterComponent },
	{ path: 'support/register', component: SupportAdminRegisterComponent },
	{ path: 'sales/register', component: SalesAdminRegisterComponent },
	{ path: 'parent/register', component: ParentRegisterComponent },
	{ path: 'expert/register', component: ExpertRegisterComponent },

	{ path: 'support/dashboard', component: AdminDashboardComponent },
	{ path: 'sales-admin/dashboard', component: SalesAdminDashboardComponent },
	{ path: 'parent/dashboard', component: ParentDashboardComponent },
	{ path: 'expert/dashboard', component: ExpertDashboardComponent },

	{ path: 'support/multiple-session', component: MultiSessionComponent },
	{ path: 'parent/multiple-session', component: MultiSessionComponent },
	{ path: 'support/new-session', component: AdminNewSessionComponent },
	{ path: 'sales-admin/newSessionRequest', component: SalesAdminNewSessionRequestComponent },
	{ path: 'parent/newSessionRequest', component: NewSessionRequestComponent },
	{ path: 'expert/expertNewSessionRequest', component: ExpertNewSessionComponent },

	{ path: 'support/parent/:id', component: AdminSelectedParentComponent, resolve: { parent: ParentDataResolver } },
	{ path: 'support/expert/:id', component: AdminExpertSelectedComponent },
	{ path: 'support/find-expert', component: FindExpertsComponent },

	{ path: 'parent/payment-page', component: PaymentComponent },
	{ path: 'parent/failure-payment', component: PaymentFailureComponent },

	// { path: '**', redirectTo: '/404' },
	{ path: '404', component: NotFoundComponent },
	{ path: 'parent/invalid-login', component: InvalidLoginComponent },

	// { path: 'parent/feedback',component:FeedbackPageComponent},
	{ path: 'expert/feedback/:expertId/:sessionId', component: FeedbackPageComponent },
	{ path: 'parent/feedback/:parentId/:sessionId', component: FeedbackPageComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
