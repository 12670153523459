import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Timestamp } from '@google-cloud/firestore';
import { timeStamp } from 'console';
// modules for exporting the data from firestore to excel
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import firebase from 'firebase/app';
import { type } from 'os';
import { HelperService } from '../helper.service';
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: 'root'
})
export class DataDownloadService {

  workbook = new Workbook();
  worksheet: any;
  sessions: any[];
  parents: any[];
  experts: any[];
  parentsWithSessions: any[];
  parentsWithAllData: any[];

  constructor(private firestore:AngularFirestore, private helper: HelperService, private notificationService: NotificationService,) { }


  // function to get session database data.
  async getSessionDatabase() {
    this.sessions = [];
    this.worksheet = this.workbook.addWorksheet('session-Database')

    this.worksheet.columns = [
      { header: 'id', key: 'id' },
      { header: 'status', key: 'status' },
      { header: 'bookedWithMultipleSessions', key: 'bookedWithMultipleSessions' },
      { header: 'multiSessionBatchId', key: 'multiSessionBatchId' },
      { header: 'createdByName', key: 'createdByName' },
      { header: 'createdOn', key: 'createdOn' },
      { header: 'createdWithName', key: 'createdWithName' },
      { header: 'sessionDate', key: 'sessionDate' },
      { header: 'sessionEndDateTime', key: 'sessionEndDateTime' },
      { header: 'startTime', key: 'startTime' },
      { header: 'endTime', key: 'endTime' },
      { header: 'expertPaid', key: 'expertPaid' },
      { header: 'serviceType', key: 'serviceType' },
      { header: 'sessionExpertName', key: 'sessionExpertName' },
      { header: 'sessionParentName', key: 'sessionParentName' },
      { header: 'sessionStudentName', key: 'sessionStudentName' },
      { header: 'sessionStudentId', key: 'sessionStudentId' },
      { header: 'sessionValue', key: 'sessionValue' },
      { header: 'slotDuration', key: 'slotDuration' },
      { header: 'createdBy', key: 'createdBy' },
      { header: 'attendedBy', key: 'attendedBy' },
      { header: 'cancelledByName', key: 'cancelledByName' },
      { header: 'cancelledOn', key: 'cancelledOn' },
      { header: 'CancellingPerson', key: 'CancellingPerson' },
      { header: 'meetingEndedAt', key: 'meetingEndedAt' },
      { header: 'meetingHostEmail', key: 'meetingHostEmail' },
      { header: 'parentFeedback', key: 'parentFeedback' },   
      { header: 'parentFeedbackRating', key: 'parentFeedbackRating' },   
      {header:"feedbackImprovementFields",key:"feedbackImprovementFields"},
      { header: 'expertFeedback', key: 'expertFeedback' },   
      { header: 'expertFeedbackRating', key: 'expertFeedbackRating' },    
    ]
    this.worksheet.getRow(1).font = { bold: true }

    const sessionRef = this.firestore.collectionGroup('sessions');
    sessionRef.get().toPromise().then(async (results) => {
      results.forEach(async (doc) => {
        let session: any = doc.data();
        this.sessions.push(session);
      })

      this.addSessionDbDataToExcelSheet();
    })

  }


  addSessionDbDataToExcelSheet() {
    this.sessions.forEach(doc => {
      let session: any = doc;
      let excelData: any = {};

      let date = new Date(session?.createdOn?.toDate())
      let correctDate = this.helper.istTime3(date, 0, 0);
      let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let dateStringInParts = localSessionDateString.split(',');
      let onlyDateString = dateStringInParts[0];

      let onlyDateStringOfSessionDate = '';
      let sessionDateStamp: Timestamp;
      sessionDateStamp = session?.sessionDate;
      if(sessionDateStamp){
        let sessionDate = new Date(sessionDateStamp.toDate())
        let correctsessionDate = this.helper.istTime3(sessionDate, 0, 0);
        let localSessionDateString = correctsessionDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
        let dateStringInParts = localSessionDateString.split(',');
        onlyDateStringOfSessionDate = dateStringInParts[0];
      }

      let onlyDateStringOfSessionEndDateTime = '';
      let sessionEndStamp: Timestamp;
      sessionEndStamp = session?.sessionEndDateTime;
      if(sessionEndStamp){
        let sessionEndDate = new Date(sessionEndStamp.toDate())
        let correctSessionEndDate = this.helper.istTime3(sessionEndDate, 0, 0);
        let localSessionEndDate = correctSessionEndDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
        let dateStringInPartsOfSessionEndDate = localSessionEndDate.split(',');
        onlyDateStringOfSessionEndDateTime = dateStringInPartsOfSessionEndDate[0];
      }  
      excelData.id = session?.id;
      excelData.status = session?.status;
      excelData.bookedWithMultipleSessions = session?.bookedWithMultipleSessions;
      excelData.multiSessionBatchId = session?.multiSessionBatchId;
      excelData.createdByName = session?.createdByName;
      excelData.createdOn = onlyDateString;
      excelData.createdWithName = session?.createdWithName;
      excelData.sessionDate = onlyDateStringOfSessionDate;
      excelData.sessionEndDateTime = onlyDateStringOfSessionEndDateTime;
      excelData.startTime = session?.startTime;
      excelData.endTime = session?.endTime;
      excelData.expertPaid = session?.expertPaid;
      excelData.serviceType = session?.serviceType;
      excelData.sessionExpertName = session?.sessionExpertName;
      excelData.sessionParentName = session?.sessionParentName;
      excelData.sessionStudentName = session?.sessionStudentName;
      excelData.sessionStudentId = session?.sessionStudentId;
      excelData.sessionValue = session?.sessionValue;
      excelData.slotDuration = session?.slotDuration;
      excelData.createdBy = session?.createdBy,
      excelData.attendedBy = session?.attendedBy;
      excelData.cancelledByName = session?.cancelledByName;
      excelData.cancelledOn = session?.cancelledOn;
      excelData.CancellingPerson = session?.CancellingPerson;
      excelData.meetingEndedAt = session?.meetingEndedAt;
      excelData.meetingHostEmail = session?.meetingHostEmail;

      if (session.feedback && "parent" in session?.feedback){
        excelData.parentFeedback=session?.feedback?.parent["comment"];
        excelData.parentFeedbackRating=session?.feedback?.parent["feedback"];
        excelData.feedbackImprovementFields= session?.feedback?.parent["improvement_fields"];
      }
      
      if (session.feedback && "expert" in session?.feedback){
        excelData.expertFeedback=session?.feedback?.expert["comment"];
        excelData.expertFeedbackRating=session?.feedback?.expert["feedback"];
      }

      // adding the data as a row in excel sheet
      this.worksheet.addRow({
        ...excelData,
      })
    })

    this.addSessionDbToFile();
  }

  addSessionDbToFile() {
    let fName = "session-data"
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
      console.log('done successfully')
    });
  }


  // function to get parents database data.
  async getParentDbData() {
    this.parents = [];
    this.worksheet = this.workbook.addWorksheet('parents-details')

    this.worksheet.columns = [
      { header: 'userId', key: 'userId' },
      { header: 'role', key: 'role' },
      { header: 'activityStatus', key: 'activityStatus' },
      { header: 'ageRange', key: 'ageRange' },
      { header: 'authenticated', key: 'authenticated' },
      { header: 'registered', key: 'registered' },
      { header: 'verified', key: 'verified' },
      { header: 'childName', key: 'childName' },
      { header: 'conditions', key: 'conditions' },
      { header: 'otherConditions', key: 'otherConditions' },
      { header: 'countryCode', key: 'countryCode' },
      { header: 'phoneNumber', key: 'phoneNumber' },
      { header: 'whatsappNumber', key: 'whatsappNumber' },
      { header: 'createdOn', key: 'createdOn' },
      { header: 'email', key: 'email' },
      { header: 'interaktId', key: 'interaktId' },
      { header: 'languages', key: 'languages' },
      { header: 'location', key: 'location' },
      { header: 'myExperts', key: 'myExperts' },
      { header: 'numberOfSessions', key: 'numberOfSessions' },
      { header: 'sessionPackValue', key: 'sessionPackValue' },
      { header: 'parentName', key: 'parentName' },
    ]
    this.worksheet.getRow(1).font = { bold: true }

    const parentRef = this.firestore.collection('users', ref => ref.where('role', "==", "parent"));
    parentRef.get().toPromise().then(async (results) => {
      console.log('total parents ', results.size);
      let count = 1;
      results.forEach(async (doc) => {
        let parent: any = doc.data();

        console.log(count, 'parent ', parent.id)
        this.parents.push(parent);
        count++;
      })

      this.addParentsDbDataToExcelSheet();
    })

  }


  addParentsDbDataToExcelSheet() {
    let count = 1;
    this.parents.sort(function (x, y) {
      return x.fullName - y.fullName
    })

    this.parents.forEach(doc => {
      count++;
      let parent: any = doc;

      let excelData: any = {};
      let date = new Date(parent.createdOn.toDate())
      let correctDate = this.helper.istTime3(date, 0, 0);
      let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let dateStringInParts = localSessionDateString.split(',');
      let onlyDateString = dateStringInParts[0];

      let expertsDetailsString: any = "";
      let experts = [];
      experts = parent?.myExperts;
      let counts = 'Expert';
      let index = 0;
      let expertsDetailsContainer = [];

      experts.forEach((expert) => {
        expertsDetailsString = "";
        let correctIndex = index + 1;
        let expertCount = counts + correctIndex;
        index++;

        let teacher: any = expert;
        expertsDetailsString = expertCount + "=> " + teacher?.educatorId + ', ';

        let services = [];
        services = teacher?.service;

        for(let i=0; i<services.length; i++){
          let service = services[i];

          expertsDetailsString = expertsDetailsString + service + ', ';
        }

        expertsDetailsString = expertsDetailsString + teacher?.reccomendedFrequency + ', ' + teacher?.educatorName;

        expertsDetailsContainer.push(expertsDetailsString);
      })


      excelData.userId = parent?.id;
      excelData.role = parent?.role;
      excelData.activityStatus = parent?.activityStatus;
      excelData.ageRange = parent?.ageRange;
      excelData.authenticated = parent?.authenticated;
      excelData.registered = parent?.registered;
      excelData.verified = parent?.verified;
      excelData.childName = parent?.childName;
      excelData.conditions = parent?.conditions;
      excelData.otherConditions = parent?.otherConditions;
      excelData.countryCode = parent?.countryCode;
      excelData.phoneNumber = parent?.phoneNumber;
      excelData.whatsappNumber = parent?.whatsappNumber;
      excelData.createdOn = onlyDateString;
      excelData.email = parent?.email;
      excelData.interaktId = parent?.interaktId;
      excelData.languages = parent?.languages;
      excelData.location = parent?.location;
      excelData.numberOfSessions = parent?.numberOfSessions;
      excelData.sessionPackValue = parent?.sessionPackValue;
      excelData.myExperts = parent?.myExperts;
      // excelData.myExperts = expertsDetailsContainer;
      excelData.parentName = parent?.fullName;

      // adding the data as a row in excel sheet
      this.worksheet.addRow({
        ...excelData,
      })
    })

    this.addParentsDbDataToFile();
  }

  addParentsDbDataToFile() {
    let fName = "parents-data"
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
      console.log('done successfully')
    });
  }


  // function to get experts database data
  async getExpertDbData() {
    this.experts = [];
    this.worksheet = this.workbook.addWorksheet('experts-details')

    this.worksheet.columns = [
      { header: 'userId', key: 'userId' },
      { header: 'ageGroups', key: 'ageGroups' },
      { header: 'condtion', key: 'condtion' },
      { header: 'countryCode', key: 'countryCode' },
      { header: 'phoneNumber', key: 'phoneNumber' },
      { header: 'whatsappNumber', key: 'whatsappNumber' },
      { header: 'createdOn', key: 'createdOn' },
      { header: 'dateOfBirth', key: 'dateOfBirth' },
      { header: 'email', key: 'email' },
      { header: 'startingAgeRange', key: 'startingAgeRange' },
      { header: 'endingAgeRange', key: 'endingAgeRange' },
      { header: 'expertName', key: 'expertName' },
      { header: 'interaktId', key: 'interaktId' },
      { header: 'languages', key: 'languages' },
      { header: 'serviceTypes', key: 'serviceTypes' },
      { header: 'timeZone', key: 'timeZone' },
      { header: 'verified', key: 'verified' },
      { header: 'authenticated', key: 'authenticated' },
      { header: 'registered', key: 'registered' },
      { header: 'yearsOfExperience', key: 'yearsOfExperience' },
      { header: 'activityStatus', key: 'activityStatus' },
      { header: 'workingHours', key: 'workingHours' },
    ]
    this.worksheet.getRow(1).font = { bold: true }

    const expertRef = this.firestore.collection('users', ref => ref.where('role', "==", "expert"));
    expertRef.get().toPromise().then(async (results) => {
      results.forEach(async (doc) => {
        let expert: any = doc.data();
        this.experts.push(expert);
      })

      this.addExpertsDbDataToExcelSheet();
    })

  }


  addExpertsDbDataToExcelSheet() {
    this.experts.sort(function (x, y) {
      return x.fullName - y.fullName
    })

    this.experts.forEach(doc => {
      let expert: any = doc;

      let excelData: any = {};
      let date = new Date(expert.createdOn.toDate())
      let correctDate = this.helper.istTime3(date, 0, 0);
      let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let dateStringInParts = localSessionDateString.split(',');
      let onlyDateString = dateStringInParts[0];

      let dateOfBirth = new Date(expert.createdOn.toDate())
      let correctDateOfBirth = this.helper.istTime3(dateOfBirth, 0, 0);
      let localDateOfBirthString = correctDateOfBirth.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let dateOfBirthStringInParts = localDateOfBirthString.split(',');
      let onlyDateOfBirthString = dateOfBirthStringInParts[0];

      excelData.userId = expert?.id;
      excelData.ageGroups = expert?.ageGroups;
      excelData.condtion = expert?.condtion;
      excelData.countryCode = expert?.countryCode;
      excelData.phoneNumber = expert?.phoneNumber;
      excelData.whatsappNumber = expert?.whatsappNumber;
      excelData.createdOn = onlyDateString;
      excelData.dateOfBirth = onlyDateOfBirthString;
      excelData.email = expert?.email;
      excelData.startingAgeRange = expert?.startingAgeRange;
      excelData.endingAgeRange = expert?.endingAgeRange;
      excelData.expertName = expert?.fullName;
      excelData.interaktId = expert?.interaktId;
      excelData.languages = expert?.languages;
      excelData.serviceTypes = expert?.serviceTypes;
      excelData.timeZone = expert?.timeZone;
      excelData.verified = expert?.verified;
      excelData.authenticated = expert?.authenticated;
      excelData.registered = expert?.registered;
      excelData.yearsOfExperience = expert?.yearsOfExperience;
      excelData.activityStatus = expert?.activityStatus,
      excelData.workingHours=expert?.workingHours

      // adding the data as a row in excel sheet
      this.worksheet.addRow({
        ...excelData,
      })
    })

    this.addExpertsDbDataToFile();
  }

  addExpertsDbDataToFile() {
    let fName = "experts-data"
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
      console.log('done successfully')
    });
  }


  // function to get the parent balance snapshot data
  async getParentBalanceSnapshotDetails() {
    this.parents = [];
    this.worksheet = this.workbook.addWorksheet('parents-snapshot-details')

    this.worksheet.columns = [
      { header: 'userId', key: 'userId' },
      { header: 'createdOn', key: 'createdOn' },
      { header: 'parentName', key: 'parentName' },
      { header: 'childName', key: 'childName' },
      { header: 'status', key: 'status' },
      { header: 'experts', key: 'experts' },
      { header: 'registered', key: 'registered' },
      { header: 'countryCode', key: 'countryCode' },
      { header: 'phoneNumber', key: 'phoneNumber' },
      { header: 'whatsappNumber', key: 'whatsappNumber' },
      { header: 'lastAttendedSession', key: 'lastAttendedSession' },
      { header: 'lastScheduledSession', key: 'lastScheduledSession' },
      { header: 'totalScheduledSessions', key: 'totalScheduledSessions' },
      { header: 'currentBalance', key: 'currentBalance' },
      { header: 'validTill', key: 'validTill' },
      { header: 'gracePeriod', key: 'gracePeriod' },
      { header: 'interaktId', key: 'interaktId' },
      { header: 'email', key: 'email' },
    ]
    this.worksheet.getRow(1).font = { bold: true }

    const parentRef = this.firestore.collection('users', ref => ref.where('role', "==", "parent"));
    parentRef.get().toPromise().then(async (results) => {
      results.forEach(async (doc) => {
        let parent: any = doc.data();
        this.parents.push(parent);
      })

      this.getSessionForParents();
    })

  }

  getSessionForParents() {
    this.parentsWithSessions = [];
    let count = 0;

    this.parents.forEach(doc => {
      let parent: any = doc;
      let totalScheduledSessions = 0;

      let sessionRef = this.firestore.collectionGroup('sessions', ref => ref.where('sessionStudentId', '==', parent.id).orderBy('sessionEndDateTime', 'asc'));
      sessionRef.get().toPromise().then(results => {
        count++;
        if (!results.empty) {
          let maxDate = new Date(8640000000000000);
          let minDate = new Date(-8640000000000000);
          let now = new Date();
          let previousSessionMinDate = new Date(-8640000000000000);
          const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

          results.forEach(doc => {
            let session: any = doc.data();
            let endTime: Date = new Date(session.sessionEndDateTime.toDate());

            let sessionDate: Date = new Date(session.sessionDate.toDate());
            let correctDate = this.helper.istTime3(sessionDate, 0, 0);
            let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
            let dateStringInParts = localSessionDateString.split(',');
            let dateString = dateStringInParts[0];

            if (session.status == "Scheduled" && endTime > previousSessionMinDate) {
              parent['lastScheduledSession'] = dateString;
              previousSessionMinDate = new Date(endTime);
            }

            if (session.status === "Scheduled" && this.isFutureSessionForRenewalList(session)) {
              totalScheduledSessions += 1;
            }

            if (endTime.getTime() <= now.getTime() && endTime.getTime() > minDate.getTime()) {
              if (session.status == "Attended") {
                parent['lastAttendedSession'] = dateString;
              }

              parent['lastSession'] = dateString;
              minDate = new Date(endTime);
            } else if (endTime.getTime() > now.getTime() && endTime.getTime() < maxDate.getTime()) {
              parent.nextSession = dateString;
              maxDate = new Date(endTime);
            }
          })

        }

        parent.totalScheduledSessions = totalScheduledSessions;
        this.parentsWithSessions.push(parent);

        if(count == this.parents.length){
          this.addBalanceToParentsData();
        }
      })
    })
  }

  // function to check if the session is a past session or future session than current time.
  isFutureSessionForRenewalList(session) {
    let sessionDate: Date = session.sessionDate.toDate();
    let exactSessionDate = this.helper.istTime2(sessionDate);

    // startTime is HH:MM
    let startTime = session.startTime.split(":");
    const startingHour = parseInt(startTime[0], 10);
    const startingMinute = parseInt(startTime[1], 10);

    //Convert start time to minutes and add to session date to get exact start date time.
    exactSessionDate.setMinutes(exactSessionDate.getMinutes() + (startingHour * 60) + startingMinute);

    let currentTime = new Date();
    let now = this.helper.istTime(currentTime);

    // if the session start time is greater than the current time.
    if (exactSessionDate.getTime() > now.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  addBalanceToParentsData() {
    this.parentsWithAllData = [];
    let count = 0;
    this.parentsWithSessions.forEach(doc => {
      let parent: any = doc;
      let currentBalance: any;

      let balRef = this.firestore.collection('balances').doc(parent.id);
      balRef.get().toPromise().then((balanceDoc) => {
        count++;
        if (balanceDoc.exists) {
          let balanceData: any = balanceDoc.data();
          console.log(count, 'parent', balanceData)

          parent.currentBalance = balanceData.currentBalance;
          this.parentsWithAllData.push(parent);
        } else {
          parent.currentBalance = "not found";
          this.parentsWithAllData.push(parent);
        }

        if(count == this.parentsWithSessions.length){
          this.addParentsBalanceSnapshotDataToExcelSheet();
        }
      })

    })
  }

  addParentsBalanceSnapshotDataToExcelSheet() {
    let count = 1;
    this.parentsWithAllData.sort(function (x, y) {
      return x.fullName - y.fullName
    })

    this.parentsWithAllData.forEach(doc => {
      count++;
      let parent: any = doc;

      let excelData: any = {};
      let date = new Date(parent.createdOn.toDate())
      let correctDate = this.helper.istTime3(date, 0, 0);
      let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let dateStringInParts = localSessionDateString.split(',');
      let onlyDateString = dateStringInParts[0];

      let validDate = new Date(parent.validTill?.toDate())
      let correctValidDate = this.helper.istTime3(validDate, 0, 0);
      let localValidDateString = correctValidDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let validDateStringInParts = localValidDateString.split(',');
      let validDateString = validDateStringInParts[0];

      let graceDate = new Date(parent.gracePeriod?.toDate())
      let correctGraceDate = this.helper.istTime3(graceDate, 0, 0);
      let localGraceDateString = correctGraceDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let graceDateStringInParts = localGraceDateString.split(',');
      let graceDateString = graceDateStringInParts[0];

      let expertsNameString: any = "";
      let experts = [];
      experts = parent.myExperts;
      // console.log('experts ', experts)
      experts.forEach((expert) => {
        let teacher: any = expert;
        // console.log('teacher ', teacher)
        expertsNameString = expertsNameString + teacher.educatorName + ","
      })

      excelData.userId = parent.id;
      excelData.createdOn = onlyDateString;
      excelData.parentName = parent.fullName;
      excelData.childName = parent.childName;
      excelData.status = parent.activityStatus;
      excelData.experts = expertsNameString;
      excelData.registered = parent.registered;
      excelData.countryCode = parent.countryCode;
      excelData.phoneNumber = parent.phoneNumber;
      excelData.whatsappNumber = parent.whatsappNumber;
      excelData.lastAttendedSession = parent.lastAttendedSession;
      excelData.lastScheduledSession = parent.lastScheduledSession;
      excelData.totalScheduledSessions = parent.totalScheduledSessions;
      excelData.currentBalance = parent.currentBalance;
      excelData.validTill=validDateString;  
      excelData.gracePeriod=graceDateString;
      excelData.interaktId=parent.interaktId;
      excelData.email=parent.email;


      // adding the data as a row in excel sheet
      this.worksheet.addRow({
        ...excelData,
      })
    })

    this.addParentsBalanceSnapShotDataToFile();
  }

  addParentsBalanceSnapShotDataToFile() {
    let fName = "parents-balance-snapshot-data"
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
      console.log('done successfully')
    });
  }


  // function to get experts balance snapshot data
  async getExpertsBalanceSnapshotDetails() {
    this.parents = [];
    this.worksheet = this.workbook.addWorksheet('experts-balance-details')

    this.worksheet.columns = [
      { header: 'userId', key: 'userId' },
      { header: 'createdOn', key: 'createdOn' },
      { header: 'expertName', key: 'expertName' },
      { header: 'status', key: 'status' },
      { header: 'registered', key: 'registered' },
      { header: 'countryCode', key: 'countryCode' },
      { header: 'phoneNumber', key: 'phoneNumber' },
      { header: 'lastAttendedSession', key: 'lastAttendedSession' },
      { header: 'lastScheduledSession', key: 'lastScheduledSession' },
      { header: 'totalScheduledSessions', key: 'totalScheduledSessions' },
    ]
    this.worksheet.getRow(1).font = { bold: true }

    const parentRef = this.firestore.collection('users', ref => ref.where('role', "==", "expert"));
    parentRef.get().toPromise().then(async (results) => {
      console.log('total parents ', results.size);
      let count = 0;
      results.forEach(async (doc) => {
        count++;
        let parent: any = doc.data();

        if(parent.verified == true){
          this.parents.push(parent);
        }
      })

      if(count == results.size)
        this.getSessionsForExperts();
    })

  }

  getSessionsForExperts() {
    this.parentsWithAllData = [];
    let count = 0;
    
    this.parents.forEach(doc => {
      let parent: any = doc;
      let totalScheduledSessions = 0;

      let sessionRef = this.firestore.collectionGroup('sessions', ref => ref.where('sessionExpertId', '==', parent.id));
      sessionRef.get().toPromise().then(results => {
        count++;
        if (!results.empty) {
          let maxDate = new Date(8640000000000000);
          let minDate = new Date(-8640000000000000);
          let now = new Date();
          let previousSessionMinDate = new Date(-8640000000000000);
          const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

          results.forEach(doc => {
            let session: any = doc.data();
            let endTime: Date = new Date(session.sessionEndDateTime.toDate());

            let sessionDate: Date = new Date(session.sessionDate.toDate());
            let correctDate = this.helper.istTime3(sessionDate, 0, 0);
            let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
            let dateStringInParts = localSessionDateString.split(',');
            let dateString = dateStringInParts[0];

            // let dateString = sessionDate.getDate() + " " + monthNames[sessionDate.getMonth()] + ", " + sessionDate.getFullYear() + "; " + session.startTime + " IST";

            if (session.status == "Scheduled" && endTime > previousSessionMinDate) {
              parent.lastScheduledSession = dateString;
              previousSessionMinDate = new Date(endTime);
            }

            if (session.status === "Scheduled" && this.isFutureSessionForRenewalList(session)) {
              totalScheduledSessions += 1;
            }

            if (endTime.getTime() <= now.getTime() && endTime.getTime() > minDate.getTime()) {
              if (session.status == "Attended") {
                parent.lastAttendedSession = dateString;
              }

              parent.lastSession = dateString;
              minDate = new Date(endTime);
            } else if (endTime.getTime() > now.getTime() && endTime.getTime() < maxDate.getTime()) {
              parent.nextSession = dateString;
              maxDate = new Date(endTime);
            }
          })

        } else {
          parent.lastAttendedSession = '-';
          parent.lastScheduledSession = '-';
        }

        parent.totalScheduledSessions = totalScheduledSessions;
        console.log(count);
        this.parentsWithAllData.push(parent);
        
        if(count == this.parents.length){
          this.addExpertBalanceSnapshotDataToExcelSheet();
        }
      }).catch(err => {
        count++;
        console.log('error ', err);
        parent.lastAttendedSession = '-';
        parent.lastScheduledSession = '-';
        parent.totalScheduledSessions = totalScheduledSessions;
        console.log(count);
        this.parentsWithAllData.push(parent);

        if(count == this.parents.length){
          this.addExpertBalanceSnapshotDataToExcelSheet();
        }
      })
    })
  }

  

  addExpertBalanceSnapshotDataToExcelSheet() {
    let count = 1;
    this.parentsWithAllData.sort(function (x, y) {
      return x.fullName - y.fullName
    })

    this.parentsWithAllData.forEach(doc => {
      count++;
      let parent: any = doc;

      let excelData: any = {};
      let date = new Date(parent.createdOn.toDate())
      let correctDate = this.helper.istTime3(date, 0, 0);
      let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let dateStringInParts = localSessionDateString.split(',');
      let onlyDateString = dateStringInParts[0];

      excelData.userId = parent.id;
      excelData.createdOn = onlyDateString;
      excelData.expertName = parent.fullName;
      excelData.status = parent.activityStatus;
      excelData.registered = parent.registered;
      excelData.countryCode = parent.countryCode;
      excelData.phoneNumber = parent.phoneNumber;
      excelData.lastAttendedSession = parent.lastAttendedSession;
      excelData.lastScheduledSession = parent.lastScheduledSession;
      excelData.totalScheduledSessions = parent.totalScheduledSessions;


      // adding the data as a row in excel sheet
      this.worksheet.addRow({
        ...excelData,
      })
    })

    this.expertBalanceSnapshotDataToFile();
  }

  expertBalanceSnapshotDataToFile() {
    let fName = "experts-balance-snapshot-data"
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
      console.log('done successfully')
    });
  }

  // function to get active parents data
  async getActiveParentDbData() {
    this.parents = [];
    this.parentsWithSessions=[];
    this.worksheet = this.workbook.addWorksheet('parents-details')

    this.worksheet.columns = [
      { header: 'userId', key: 'userId' },
      { header: 'role', key: 'role' },
      { header: 'activityStatus', key: 'activityStatus' },
      { header: 'ageRange', key: 'ageRange' },
      { header: 'authenticated', key: 'authenticated' },
      { header: 'registered', key: 'registered' },
      { header: 'verified', key: 'verified' },
      { header: 'childName', key: 'childName' },
      { header: 'conditions', key: 'conditions' },
      { header: 'otherConditions', key: 'otherConditions' },
      { header: 'countryCode', key: 'countryCode' },
      { header: 'phoneNumber', key: 'phoneNumber' },
      { header: 'whatsappNumber', key: 'whatsappNumber' },
      { header: 'createdOn', key: 'createdOn' },
      { header: 'email', key: 'email' },
      { header: 'interaktId', key: 'interaktId' },
      { header: 'languages', key: 'languages' },
      { header: 'location', key: 'location' },
      { header: 'myExperts', key: 'myExperts' },
      { header: 'numberOfSessions', key: 'numberOfSessions' },
      { header: 'sessionPackValue', key: 'sessionPackValue' },
      { header: 'parentName', key: 'parentName' },
      { header: 'currentBalance', key: 'currentBalance' },
      { header: 'validTill', key: 'validTill' },
      { header: 'gracePeriod', key: 'gracePeriod' },
    ]
    this.worksheet.getRow(1).font = { bold: true }

    const parentRef = this.firestore.collection('users', ref => ref.where('role', "==", "parent").where("activityStatus","==","Active"));
    parentRef.get().toPromise().then(async (results) => {
     
      let count = 1;
      results.forEach(async (doc) => {
        let parent: any = doc.data();
          this.parents.push(parent);
          count++;
      })
      
      if(count == results.size+1){
        this.getActiveParentsSessionData();
      }
    })   
  }

  getActiveParentsSessionData(){
    let count = 1;
    this.parents.forEach(parent => {
      const sessionRef = this.firestore.collectionGroup('sessions', ref => ref.where('sessionStudentId',"==",parent.id).where('sessionDate', ">=",  this.helper.subtractDays(90)));
        sessionRef.get().toPromise().then(async (results) => {
          if(results.size>0){
            this.parentsWithSessions.push(parent);
          }
          count++;
        
          if(count==this.parents.length+1){
            this.addActiveParentsDbDataToExcelSheet();
          }
        })  
        
    })
   
   
  }

  addActiveParentsDbDataToExcelSheet() {
    let count = 1;
    this.parentsWithSessions.sort(function (x, y) {
      return x.fullName - y.fullName
    })
  
    this.parentsWithSessions.forEach(doc => {
      count++;
      let parent: any = doc;

      let excelData: any = {};
      let date = new Date(parent.createdOn.toDate())
      let correctDate = this.helper.istTime3(date, 0, 0);
      let localSessionDateString = correctDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let dateStringInParts = localSessionDateString.split(',');
      let onlyDateString = dateStringInParts[0];

      let validDate = new Date(parent.validTill?.toDate())
      let correctValidDate = this.helper.istTime3(validDate, 0, 0);
      let localValidDateString = correctValidDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let validDateStringInParts = localValidDateString.split(',');
      let validDateString = validDateStringInParts[0];

      let graceDate = new Date(parent.gracePeriod?.toDate())
      let correctGraceDate = this.helper.istTime3(graceDate, 0, 0);
      let localGraceDateString = correctGraceDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      let graceDateStringInParts = localGraceDateString.split(',');
      let graceDateString = graceDateStringInParts[0];


      let expertsDetailsString: any = "";
      let experts = [];
      experts = parent?.myExperts;
      let counts = 'Expert';
      let index = 0;
      let expertsDetailsContainer = [];

      experts.forEach((expert) => {
        expertsDetailsString = "";
        let correctIndex = index + 1;
        let expertCount = counts + correctIndex;
        index++;

        let teacher: any = expert;
        expertsDetailsString = expertCount + "=> " + teacher?.educatorId + ', ';

        let services = [];
        services = teacher?.service;

        for(let i=0; i<services.length; i++){
          let service = services[i];

          expertsDetailsString = expertsDetailsString + service + ', ';
        }

        expertsDetailsString = expertsDetailsString + teacher?.reccomendedFrequency + ', ' + teacher?.educatorName;

        expertsDetailsContainer.push(expertsDetailsString);
      })


      excelData.userId = parent?.id;
      excelData.role = parent?.role;
      excelData.activityStatus = parent?.activityStatus;
      excelData.ageRange = parent?.ageRange;
      excelData.authenticated = parent?.authenticated;
      excelData.registered = parent?.registered;
      excelData.verified = parent?.verified;
      excelData.childName = parent?.childName;
      excelData.conditions = parent?.conditions;
      excelData.otherConditions = parent?.otherConditions;
      excelData.countryCode = parent?.countryCode;
      excelData.phoneNumber = parent?.phoneNumber;
      excelData.whatsappNumber = parent?.whatsappNumber;
      excelData.createdOn = onlyDateString;
      excelData.email = parent?.email;
      excelData.interaktId = parent?.interaktId;
      excelData.languages = parent?.languages;
      excelData.location = parent?.location;
      excelData.numberOfSessions = parent?.numberOfSessions;
      excelData.sessionPackValue = parent?.sessionPackValue;
      excelData.myExperts = parent?.myExperts;
      // excelData.myExperts = expertsDetailsContainer;
      excelData.parentName = parent?.fullName;
      excelData.currentBalance=parent?.currentBalance;
      excelData.validTill=validDateString;
      excelData.gracePeriod=graceDateString;

      // adding the data as a row in excel sheet
      this.worksheet.addRow({
        ...excelData,
      })
    })

    this.addActiveParentsDbDataToFile();
  }

  addActiveParentsDbDataToFile() {
    let fName = "active-parents-data"
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
      console.log('done successfully')
    });
  }

 // function to get In active parents data
 async getInActiveParentDbData() {
  this.parents = [];
  this.parentsWithSessions=[];
  this.worksheet = this.workbook.addWorksheet('parents-details')

  this.worksheet.columns = [
    { header: 'userId', key: 'userId' },
    { header: 'role', key: 'role' },
    { header: 'activityStatus', key: 'activityStatus' },
    { header: 'ageRange', key: 'ageRange' },
    { header: 'authenticated', key: 'authenticated' },
    { header: 'registered', key: 'registered' },
    { header: 'verified', key: 'verified' },
    { header: 'childName', key: 'childName' },
    { header: 'conditions', key: 'conditions' },
    { header: 'otherConditions', key: 'otherConditions' },
    { header: 'countryCode', key: 'countryCode' },
    { header: 'phoneNumber', key: 'phoneNumber' },
    { header: 'whatsappNumber', key: 'whatsappNumber' },
    { header: 'createdOn', key: 'createdOn' },
    { header: 'email', key: 'email' },
    { header: 'interaktId', key: 'interaktId' },
    { header: 'languages', key: 'languages' },
    { header: 'location', key: 'location' },
    { header: 'myExperts', key: 'myExperts' },
    { header: 'numberOfSessions', key: 'numberOfSessions' },
    { header: 'sessionPackValue', key: 'sessionPackValue' },
    { header: 'parentName', key: 'parentName' },
    { header: 'currentBalance', key: 'currentBalance' },
    { header: 'validTill', key: 'validTill' },
    { header: 'gracePeriod', key: 'gracePeriod' },
  ]
  this.worksheet.getRow(1).font = { bold: true }

  const parentRef = this.firestore.collection('users', ref => ref.where('role', "==", "parent").where("activityStatus","==","Active"));
  parentRef.get().toPromise().then(async (results) => {
   
    let count = 1;
    results.forEach(async (doc) => {
      let parent: any = doc.data();
    
        this.parents.push(parent);
        count++;
    })
    
    if(count == results.size+1){
      this.getInactiveParentsSessionData();
    }
  })   
}

getInactiveParentsSessionData(){
  let count = 1;
  this.parents.forEach(parent => {
    const sessionRef = this.firestore.collectionGroup('sessions', ref => ref.where('sessionStudentId',"==",parent.id).where('sessionDate', ">=",  this.helper.subtractDays(90)));
      sessionRef.get().toPromise().then(async (results) => {
        if(results.size==0){
          this.parentsWithSessions.push(parent);
        }
        count++;
      
        if(count==this.parents.length+1){
          this.addActiveParentsDbDataToExcelSheet();
        }
      })  
      
  })
 
 
}

// function to get all Inactive parents data
async getAllInActiveParentDbData() {
  this.parents = [];
  this.parentsWithSessions=[];
  this.worksheet = this.workbook.addWorksheet('parents-details')

  this.worksheet.columns = [
    { header: 'userId', key: 'userId' },
    { header: 'role', key: 'role' },
    { header: 'activityStatus', key: 'activityStatus' },
    { header: 'ageRange', key: 'ageRange' },
    { header: 'authenticated', key: 'authenticated' },
    { header: 'registered', key: 'registered' },
    { header: 'verified', key: 'verified' },
    { header: 'childName', key: 'childName' },
    { header: 'conditions', key: 'conditions' },
    { header: 'otherConditions', key: 'otherConditions' },
    { header: 'countryCode', key: 'countryCode' },
    { header: 'phoneNumber', key: 'phoneNumber' },
    { header: 'whatsappNumber', key: 'whatsappNumber' },
    { header: 'createdOn', key: 'createdOn' },
    { header: 'email', key: 'email' },
    { header: 'interaktId', key: 'interaktId' },
    { header: 'languages', key: 'languages' },
    { header: 'location', key: 'location' },
    { header: 'myExperts', key: 'myExperts' },
    { header: 'numberOfSessions', key: 'numberOfSessions' },
    { header: 'sessionPackValue', key: 'sessionPackValue' },
    { header: 'parentName', key: 'parentName' },
  ]
  this.worksheet.getRow(1).font = { bold: true }

  const parentRef = this.firestore.collection('users', ref => ref.where('role', "==", "parent").where("activityStatus","==","Inactive"));
  parentRef.get().toPromise().then(async (results) => {
   
    let count = 1;
    results.forEach(async (doc) => {
      let parent: any = doc.data();
        this.parents.push(parent);
        count++;
    })
    
    if(count == results.size+1){
     this.addParentsDbDataToExcelSheet();
    }
  })   
}

async getAllActiveparents(){
  this.parents=[];
  const parentRef = this.firestore.collection('users', ref => ref.where('role', "==", "parent").where("activityStatus","==","Active"));
  parentRef.get().toPromise().then(async (results) => {
    let count = 1;
    results.forEach(async (doc) => {
      let parent: any = doc.data();
        this.parents.push(parent);
        count++;
        const inviteRef = this.firestore.collection('invitations', ref => ref.where('eMail', "==", parent.email));
        inviteRef.get().toPromise().then((documents) => {
          // if the parent is new and has not been registered to interakt yet.
          if (documents.empty) {
             console.log(parent.email,parent.id);
            console.log(documents,"emptyxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
          }else{
            let docs = [];
            documents.forEach((document) => {
              docs.push(document.data());
            });
            let interaktAccount: any = docs[0];
            console.log(interaktAccount,"88888888",parent.interaktId,parent.email);
           this.sendSSONotification(interaktAccount, parent.interaktId);
          }})
        // await inviteRef.get().subscribe(this.onInvitationResult);
       
      })
    console.log(count);
  })  
  
}

async sendSSONotification(invitation, userId){
  console.log(invitation,"invite");
  let parentNotificationBody = {
    "userId": userId,
    'event': 'sso announcement',
    'traits': {
      'register_link': "https://mykinderpass.com/auth/signup",
      'email': String(invitation.eMail),
      'login_link': "https://mykinderpass.com/auth/login"
    }
  }
  console.log(parentNotificationBody);
  let response = await this.notificationService.sendWhatsappNotification(parentNotificationBody);
  return response;
}

// function to get experts utilization database data
async getExpertUtilizationDbData() {
  this.experts = [];
  this.worksheet = this.workbook.addWorksheet('experts-details')

  this.worksheet.columns = [
    { header: 'userId', key: 'userId' },
    { header: 'email', key: 'email' },
    { header: 'expertName', key: 'expertName' },
    { header: 'workingHours', key: 'workingHours' },
    { header: 'mondayAttended6to9', key: 'mondayAttended6to9' },
    { header: 'mondayAttended9to12', key: 'mondayAttended9to12' },
    { header: 'mondayAttended12to15', key: 'mondayAttended12to15' },
    { header: 'mondayAttended15to18', key: 'mondayAttended15to18' },
    { header: 'mondayAttended18to21', key: 'mondayAttended18to21' },
    { header: 'mondayAttended21to24', key: 'mondayAttended21to24' },
    { header: 'tuesdayAttended6to9', key: 'tuesdayAttended6to9' },
    { header: 'tuesdayAttended9to12', key: 'tuesdayAttended9to12' },
    { header: 'tuesdayAttended12to15', key: 'tuesdayAttended12to15' },
    { header: 'tuesdayAttended15to18', key: 'tuesdayAttended15to18' },
    { header: 'tuesdayAttended18to21', key: 'tuesdayAttended18to21' },
    { header: 'tuesdayAttended21to24', key: 'tuesdayAttended21to24' },
    { header: 'wednesdayAttended6to9', key: 'wednesdayAttended6to9' },
    { header: 'wednesdayAttended9to12', key: 'wednesdayAttended9to12' },
    { header: 'wednesdayAttended12to15', key: 'wednesdayAttended12to15' },
    { header: 'wednesdayAttended15to18', key: 'wednesdayAttended15to18' },
    { header: 'wednesdayAttended18to21', key: 'wednesdayAttended18to21' },
    { header: 'wednesdayAttended21to24', key: 'wednesdayAttended21to24' },
    { header: 'thursdayAttended6to9', key: 'thursdayAttended6to9' },
    { header: 'thursdayAttended9to12', key: 'thursdayAttended9to12' },
    { header: 'thursdayAttended12to15', key: 'thursdayAttended12to15' },
    { header: 'thursdayAttended15to18', key: 'thursdayAttended15to18' },
    { header: 'thursdayAttended18to21', key: 'thursdayAttended18to21' },
    { header: 'thursdayAttended21to24', key: 'thursdayAttended21to24' },
    { header: 'fridayAttended6to9', key: 'fridayAttended6to9' },
    { header: 'fridayAttended9to12', key: 'fridayAttended9to12' },
    { header: 'fridayAttended12to15', key: 'fridayAttended12to15' },
    { header: 'fridayAttended15to18', key: 'fridayAttended15to18' },
    { header: 'fridayAttended18to21', key: 'fridayAttended18to21' },
    { header: 'fridayAttended21to24', key: 'fridayAttended21to24' },
    { header: 'saturdayAttended6to9', key: 'saturdayAttended6to9' },
    { header: 'saturdayAttended9to12', key: 'saturdayAttended9to12' },
    { header: 'saturdayAttended12to15', key: 'saturdayAttended12to15' },
    { header: 'saturdayAttended15to18', key: 'saturdayAttended15to18' },
    { header: 'saturdayAttended18to21', key: 'saturdayAttended18to21' },
    { header: 'saturdayAttended21to24', key: 'saturdayAttended21to24' },
  ]
  this.worksheet.getRow(1).font = { bold: true }

  const expertRef = this.firestore.collection('users', ref => ref.where('role', "==", "expert"));
  expertRef.get().toPromise().then(async (results) => {
    results.forEach(async (doc) => {
      let expert: any = doc.data();
      this.experts.push(expert);
    })

    this.addExpertsUtilizationDbDataToExcelSheet();
  })

}
addExpertsUtilizationDbDataToExcelSheet() {
  this.experts.sort(function (x, y) {
    return x.fullName - y.fullName
  })
  let count=0;
  this.experts.forEach(doc => {
    let expert: any = doc;
    const daySlotCnt=[{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    }];
   
    let startingDate = new Date("03/01/2023");
    let endingDate = new Date("03/29/2023");
    let startingTimeStamp = firebase.firestore.Timestamp.fromDate(startingDate)
    let endingTimeStamp = firebase.firestore.Timestamp.fromDate(endingDate)
    let sessionRef=this.firestore.collectionGroup("sessions",ref=>ref.where("sessionDate",">=",startingTimeStamp).where("sessionDate","<=",endingTimeStamp).where("sessionExpertId", "==", expert.id).where("status","==","Attended"))
    sessionRef.get().toPromise().then(async (results) => {
      results.forEach(async (doc) => {
        let session: any = doc.data();
      console.log(session);
        let hours= session.startTime.split(":");
        let startTimeMin= parseInt(hours[0])*60+parseInt(hours[1]);
       console.log(hours,"hr")
        let date=session.sessionDate.toDate();
        let day=date.getDay();
       console.log(day,"day")
        if (startTimeMin >= 360 && startTimeMin < 540) {
          daySlotCnt[day]['6-9']++;
          // timeSlots['6-9']++;
        } else if (startTimeMin >= 540 && startTimeMin < 720) {
          daySlotCnt[day]['9-12']++;
        } else if (startTimeMin >= 720 && startTimeMin < 900) {
          daySlotCnt[day]['12-15']++;
        } else if (startTimeMin >= 900 && startTimeMin < 1080) {
          daySlotCnt[day]['15-18']++;
        } else if (startTimeMin >= 1080 && startTimeMin < 1260) {
          daySlotCnt[day]['18-21']++;
        } else {
          daySlotCnt[day]['21-24']++;
        }
      })
      count++;
      this.addExpertsUtilizationSessionData(daySlotCnt,expert,count);  
    }) 
  })

}

addExpertsUtilizationSessionData(daySlotCnt,expert,count) {
  console.log(daySlotCnt,"skdl",expert);
 
  let startingDate = new Date("03/01/2023");
  let endingDate = new Date("03/29/2023");
  let startingTimeStamp = firebase.firestore.Timestamp.fromDate(startingDate)
  let endingTimeStamp = firebase.firestore.Timestamp.fromDate(endingDate)

  let consultationRef=this.firestore.collectionGroup("consultations",ref=>ref.where("sessionDate",">=",startingTimeStamp).where("sessionDate","<=",endingTimeStamp).where("sessionExpertId", "==", expert.id).where("status","==","Attended"));
  consultationRef.get().toPromise().then(async (results) => {
    console.log("consultation");
    results.forEach(async (doc) => {
      let consultation: any = doc.data();
      console.log(consultation,"consultation");
      let hours= consultation.startTime.split(":");
      let startTimeMin= parseInt(hours[0])*60+parseInt(hours[1]);
       console.log(hours,"hours")
      let date=consultation.sessionDate.toDate();
      let day=date.getDay();
      console.log(day,"day")
      if (startTimeMin >= 360 && startTimeMin < 540) {
        daySlotCnt[day]['6-9']++;
        // timeSlots['6-9']++;
      } else if (startTimeMin >= 540 && startTimeMin < 720) {
        daySlotCnt[day]['9-12']++;
      } else if (startTimeMin >= 720 && startTimeMin < 900) {
        daySlotCnt[day]['12-15']++;
      } else if (startTimeMin >= 900 && startTimeMin < 1080) {
        daySlotCnt[day]['15-18']++;
      } else if (startTimeMin >= 1080 && startTimeMin < 1260) {
        daySlotCnt[day]['18-21']++;
      } else {
        daySlotCnt[day]['21-24']++;
      }
    })
    this.addExpertsUtilizationDbDataToRows(daySlotCnt,expert,count);  
  })
   
}
addExpertsUtilizationDbDataToRows(daySlotCnt,expert,count){
  console.log(daySlotCnt,"daySlotCnt consult",count);
  let excelData: any = {};
  excelData.userId = expert?.id;
  excelData.email = expert?.email;
  excelData.expertName = expert?.fullName;
  excelData.workingHours=expert?.workingHours;
  excelData.mondayAllotted9to12=daySlotCnt[1]["6-9"];

  // adding the data as a row in excel sheet
  this.worksheet.addRow({
    ...excelData,
  })
  console.log(count,"count",this.experts.length,excelData)
  // if(count===this.experts.length){
  //   this.addExpertsUtilizationDbDataToFile();
  // }
}
addExpertsUtilizationDbDataToFile() {
  let fName = "experts-utilization-data"
  this.workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
    console.log('done successfully')
  });
}

// function to get experts working hours data
async getExpertWorkingHrsDbData() {
  this.experts = [];
  this.worksheet = this.workbook.addWorksheet('experts-details')

  this.worksheet.columns = [
    { header: 'userId', key: 'userId' },
    { header: 'email', key: 'email' },
    { header: 'expertName', key: 'expertName' },
    { header: 'activityStatus', key: 'activityStatus' },
    { header: 'workingHours', key: 'workingHours' },
    { header: 'mon6to9', key: 'mon6to9' },
    { header: 'mon9to12', key: 'mon9to12' },
    { header: 'mon12to15', key: 'mon12to15' },
    { header: 'mon15to18', key: 'mon15to18' },
    { header: 'mon18to21', key: 'mon18to21' },
    { header: 'mon21to24', key: 'mon21to24' },
    { header: 'tue6to9', key: 'tue6to9' },
    { header: 'tue9to12', key: 'tue9to12' },
    { header: 'tue12to15', key: 'tue12to15' },
    { header: 'tue15to18', key: 'tue15to18' },
    { header: 'tue18to21', key: 'tue18to21' },
    { header: 'tue21to24', key: 'tue21to24' },
    { header: 'wed6to9', key: 'wed6to9' },
    { header: 'wed9to12', key: 'wed9to12' },
    { header: 'wed12to15', key: 'wed12to15' },
    { header: 'wed15to18', key: 'wed15to18' },
    { header: 'wed18to21', key: 'wed18to21' },
    { header: 'wed21to24', key: 'wed21to24' },
    { header: 'thu6to9', key: 'thu6to9' },
    { header: 'thu9to12', key: 'thu9to12' },
    { header: 'thu12to15', key: 'thu12to15' },
    { header: 'thu15to18', key: 'thu15to18' },
    { header: 'thu18to21', key: 'thu18to21' },
    { header: 'thu21to24', key: 'thu21to24' },
    { header: 'fri6to9', key: 'fri6to9' },
    { header: 'fri9to12', key: 'fri9to12' },
    { header: 'fri12to15', key: 'fri12to15' },
    { header: 'fri15to18', key: 'fri15to18' },
    { header: 'fri18to21', key: 'fri18to21' },
    { header: 'fri21to24', key: 'fri21to24' },
    { header: 'sat6to9', key: 'sat6to9' },
    { header: 'sat9to12', key: 'sat9to12' },
    { header: 'sat12to15', key: 'sat12to15' },
    { header: 'sat15to18', key: 'sat15to18' },
    { header: 'sat18to21', key: 'sat18to21' },
    { header: 'sat21to24', key: 'sat21to24' },
    { header: 'sun6to9', key: 'sun6to9' },
    { header: 'sun9to12', key: 'sun9to12' },
    { header: 'sun12to15', key: 'sun12to15' },
    { header: 'sun15to18', key: 'sun15to18' },
    { header: 'sun18to21', key: 'sun18to21' },
    { header: 'sun21to24', key: 'sun21to24' },
  ]
  this.worksheet.getRow(1).font = { bold: true }

  const expertRef = this.firestore.collection('users', ref => ref.where('role', "==", "expert"));
  expertRef.get().toPromise().then(async (results) => {
    results.forEach(async (doc) => {
      let expert: any = doc.data();
      this.experts.push(expert);
    })

    this.addExpertsWorkingHoursDataToExcelSheet();
  })

}


addExpertsWorkingHoursDataToExcelSheet() {
  this.experts.sort(function (x, y) {
    return x.fullName - y.fullName
  })

  const date = new Date(); // current date
  const weekdayCounts = this.countWeekdaysInMonth(date);

  this.experts.forEach(doc => {
    let expert: any = doc;
    const daySlotCnt=[{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    },{
      '6-9': 0,
      '9-12': 0,
      '12-15': 0,
      '15-18': 0,
      '18-21': 0,
      '21-24': 0
    }];
    let weekdays=["mon","tue","wed","thu","fri","sat","sun"];

    let excelData: any = {};

    if(expert?.workingHours){
      expert?.workingHours.map(item=>{
       
        let startTimeHrs= item.startTime.split(":");
        let startTimeMin= parseInt(startTimeHrs[0])*60+parseInt(startTimeHrs[1]);
       
        let endTimeHrs= item.endTime.split(":");
        let endTimeMin= parseInt(endTimeHrs[0])*60+parseInt(endTimeHrs[1]);
      
        let workingTime=endTimeMin-startTimeMin;
       
        let dayIndex= weekdays.indexOf(item.weekday);
        

       // Loop through each three-hour interval in the working hours
    let startTime=startTimeMin;
    if(![360,540,720,900,1080,1260].includes(startTimeMin)){
      startTime = this.findNearestValue([360,540,720,900,1080,1260], startTimeMin);
      
    }
    for (let i = startTime; i < endTimeMin; i =i+180) {
      const intervalStart = Math.max(i, 360);
      let intervalEnd = Math.min(i + 180,1440);
      if (intervalEnd > intervalStart) {
        let intervalMinutes;
        if(intervalEnd>=endTimeMin){
          if(intervalStart<=startTimeMin){
            intervalMinutes = endTimeMin - startTimeMin;
        }else{
            intervalMinutes = endTimeMin - intervalStart;
        }

        } else if(intervalStart<=startTimeMin){
          intervalMinutes=intervalEnd-startTimeMin;
        }else{
            intervalMinutes = intervalEnd - intervalStart;
        }
       
        const slotKey = `${intervalStart/60}-${intervalEnd/60}`;
        if ( daySlotCnt&& daySlotCnt[dayIndex]&& daySlotCnt[dayIndex][slotKey] !== undefined) {
          daySlotCnt[dayIndex][slotKey] += intervalMinutes;
        }
      }
    }  
       })
    }
    
    excelData.userId = expert?.id;
    excelData.email = expert?.email;
    excelData.expertName = expert?.fullName;
    excelData.workingHours=expert?.workingHours;

    excelData.mon6to9=daySlotCnt[0]['6-9']*weekdayCounts['1'];
    excelData.mon9to12=daySlotCnt[0]['9-12']*weekdayCounts['1'];
    excelData.mon12to15=daySlotCnt[0]['12-15']*weekdayCounts['1'];
    excelData.mon15to18=daySlotCnt[0]['15-18']*weekdayCounts['1'];
    excelData.mon18to21=daySlotCnt[0]['18-21']*weekdayCounts['1'];
    excelData.mon21to24=daySlotCnt[0]['21-24']*weekdayCounts['1'];

    excelData.tue6to9=daySlotCnt[1]['6-9']*weekdayCounts['2'];
    excelData.tue9to12=daySlotCnt[1]['9-12']*weekdayCounts['2'];
    excelData.tue12to15=daySlotCnt[1]['12-15']*weekdayCounts['2'];
    excelData.tue15to18=daySlotCnt[1]['15-18']*weekdayCounts['2'];
    excelData.tue18to21=daySlotCnt[1]['18-21']*weekdayCounts['2'];
    excelData.tue21to24=daySlotCnt[1]['21-24']*weekdayCounts['2'];

    excelData.wed6to9=daySlotCnt[2]['6-9']*weekdayCounts['3'];
    excelData.wed9to12=daySlotCnt[2]['9-12']*weekdayCounts['3'];
    excelData.wed12to15=daySlotCnt[2]['12-15']*weekdayCounts['3'];
    excelData.wed15to18=daySlotCnt[2]['15-18']*weekdayCounts['3'];
    excelData.wed18to21=daySlotCnt[2]['18-21']*weekdayCounts['3'];
    excelData.wed21to24=daySlotCnt[2]['21-24']*weekdayCounts['3'];

    excelData.thu6to9=daySlotCnt[3]['6-9']*weekdayCounts['4'];
    excelData.thu9to12=daySlotCnt[3]['9-12']*weekdayCounts['4'];
    excelData.thu12to15=daySlotCnt[3]['12-15']*weekdayCounts['4'];
    excelData.thu15to18=daySlotCnt[3]['15-18']*weekdayCounts['4'];
    excelData.thu18to21=daySlotCnt[3]['18-21']*weekdayCounts['4'];
    excelData.thu21to24=daySlotCnt[3]['21-24']*weekdayCounts['4'];

    excelData.fri6to9=daySlotCnt[4]['6-9']*weekdayCounts['5'];
    excelData.fri9to12=daySlotCnt[4]['9-12']*weekdayCounts['5'];
    excelData.fri12to15=daySlotCnt[4]['12-15']*weekdayCounts['5'];
    excelData.fri15to18=daySlotCnt[4]['15-18']*weekdayCounts['5'];
    excelData.fri18to21=daySlotCnt[4]['18-21']*weekdayCounts['5'];
    excelData.fri21to24=daySlotCnt[4]['21-24']*weekdayCounts['5'];

    excelData.sat6to9=daySlotCnt[5]['6-9']*weekdayCounts['6'];
    excelData.sat9to12=daySlotCnt[5]['9-12']*weekdayCounts['6'];
    excelData.sat12to15=daySlotCnt[5]['12-15']*weekdayCounts['6'];
    excelData.sat15to18=daySlotCnt[5]['15-18']*weekdayCounts['6'];
    excelData.sat18to21=daySlotCnt[5]['18-21']*weekdayCounts['6'];
    excelData.sat21to24=daySlotCnt[5]['21-24']*weekdayCounts['6'];

    excelData.sun6to9=daySlotCnt[6]['6-9']*weekdayCounts['0'];
    excelData.sun9to12=daySlotCnt[6]['9-12']*weekdayCounts['0'];
    excelData.sun12to15=daySlotCnt[6]['12-15']*weekdayCounts['0'];
    excelData.sun15to18=daySlotCnt[6]['15-18']*weekdayCounts['0'];
    excelData.sun18to21=daySlotCnt[6]['18-21']*weekdayCounts['0'];
    excelData.sun21to24=daySlotCnt[6]['21-24']*weekdayCounts['0'];

    // adding the data as a row in excel sheet
    this.worksheet.addRow({
      ...excelData,
    })
  })

  this.addExpertsWorkingHrsDataToFile();
}

addExpertsWorkingHrsDataToFile() {
  let fName = "experts-workingHrs-data"
  this.workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx');
    console.log('done successfully')
  });
}



 findNearestValue(array, target) {
  for (let i = 0; i < array.length; i++) {
    if(array[i]<=target && target<array[i+1]){
        return array[i];
    }
  }
}
 countWeekdaysInMonth(date) {
  const year = date.getFullYear();
  const month = date.getMonth();

  // get the first and last days of the month
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);

  // initialize a dictionary to store the counts
  const weekdayCounts = {
    0: 0, // Sunday
    1: 0, // Monday
    2: 0, // Tuesday
    3: 0, // Wednesday
    4: 0, // Thursday
    5: 0, // Friday
    6: 0  // Saturday
  };

  // loop through each day of the month and update the counts
  for (let day = firstDayOfMonth.getDate(); day <= lastDayOfMonth.getDate(); day++) {
    const date = new Date(year, month, day);
    const weekday = date.getDay();
    weekdayCounts[weekday]++;
  }

  return weekdayCounts;
}




}

